import React, { memo, useState } from "react";
import styles from "./HomeLayout.module.scss";
import { sideBarNavigation } from "utils/sideBarNavigation";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
// import { PORTALS } from "utils/constants";
import mainIcon from "assets/png/fi-sr-credit-card (1).png"
import logoutIcon from "assets/png/fi-sr-redo (1).png"
import { useDispatch, useSelector } from "react-redux";
import { checkEntityPermission, combineClasses, getCurrentUser } from "utils/utils";
import { clear, setSidebarOpen } from "redux/slices/homeSlice";
import { privateGet } from "services/privateRequest";
import { LOGOUT } from "services/apiEndpoints";

import userIconImg from "assets/svg/users-icon.svg"
import instruction from "assets/svg/instruction.svg"
import { setCurrentRole } from "redux/slices/rolesAndPermissionSlice";
import { PATH } from "utils/constants";
import toast from "components/shared/toast/toast";

import bookIcon from "assets/png/bookimage.png"
import videoIcon from "assets/png/videoimage.png"
import ViewManualVideo from "components/pages/accounts/ViewManualVideo";


const LeftSideBar = ({ toggleSidebarOpen }) => {

  const [manualOpen, setManualOpen] = useState(false);
  const { sidebarOpen } = useSelector(state => state.home)
  const { currentRole } = useSelector(state => state.rolesAndPermission)
  const [openInstruction, setOpenInstruction] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // const currentRole = localStorage.getItem("currentRole")
  
  const switchLabelObj = {
    "user": "Approver",
    "approver": "User"
  }

  const hideSideNavbarLink = [
    PATH.privateRoutes.USERS.path,
    PATH.privateRoutes.MASTER.path,
    PATH.privateRoutes.EMAIL_CONFIGURATION.path,
    PATH.privateRoutes.ROLES.path,
    PATH.privateRoutes.ACCOUNTS_MASTER.path,
  ]

  const switchRole = () => {
    localStorage.setItem("currentRole", switchLabelObj[currentRole]?.toLowerCase())
    dispatch(setCurrentRole(switchLabelObj[currentRole]?.toLowerCase()))
    navigate("/home")
  }

  const clearOnLogout = async() => {
    await privateGet(LOGOUT)
    localStorage.clear()
    dispatch(clear())
    toast.success("Logged out successfully")
    navigate("/login")
  }

  const stopProp = (e) => {
    e.stopPropagation()
  }

  // const sidebarObj = useMemo(() => {
  //   const temp = Object.keys(PORTALS)
  //   return temp?.length && temp.reduce((acc, item) => {
  //     acc[item] = false
  //     return acc
  //   }, {})
  // }, [])
  // const [sidebarItemsOpen, setSidebarItemsOpen] = useState(sidebarObj);
  return (
    <div 
      className={combineClasses(styles.leftSideContainer, !sidebarOpen ? styles.sidebarClosed: styles.sidebarOpen)}
      onMouseOver={toggleSidebarOpen(true)}
    >
      <div className={styles.logoContainer}>
        <div className={styles.iconContainer}>
          <div className={styles.mainIconDiv}>
            <img src={mainIcon} alt="Credit card icon" className={styles.mainIconImage} />
          </div>
          {sidebarOpen && <p>CREDIT.CARD</p>}
        </div>
      </div>
      <div className={styles.navContainer}>
        {sideBarNavigation
          .filter(data => {
            if(currentRole === "approver" && data.path === PATH.privateRoutes.RECIEPT_WALLET.path) return false
            if(currentRole === "user" && data.path === PATH.privateRoutes.PENDING_BY_USER.path) return false
            if(getCurrentUser("roleName") === "InternalUser" && hideSideNavbarLink.includes(data.path)) return false
            return checkEntityPermission(data.path)
          })
          .map((data) => {
            const { icon: Icon } = data
            return (
              <>
                <NavLink
                  key={data.id}
                  className={({ isActive }) =>
                    `${styles.navLink} ${!sidebarOpen ? styles.sidebarClosed: ""} ${isActive && styles.activeLink}`
                  }
                  to={data.path}
                  state={{ previousPath: location.pathname }}
                >
                  <div className={styles.linksNames}>
                    <Icon className={styles.icon} />
                    {sidebarOpen && 
                    <>
                    <p className={styles.linkPageName}>{data.pageName}</p>
                    <i
                      className="fa-solid fa-arrow-right"
                    ></i>
                    </>
                    }
                  </div>
                </NavLink>
              </>
            )
          })
        }
        {["Approver","User"].includes(getCurrentUser()?.roleName) && getCurrentUser()?.isUserAndApprover ? <div
          className={
            `${styles.navLink}  ${!sidebarOpen ? styles.sidebarClosed: ""}`
          }
          onClick={switchRole}

        >
          <div className={styles.linksNames}>
            <img src={userIconImg} alt="user icon" className={styles.icon} />
            {sidebarOpen && <p className={styles.linkPageName}>Switch to {switchLabelObj[currentRole] || ""}</p>}
          </div>
        </div> : ""}


        {/* Instruction */}
        {["Approver", "User"].includes(getCurrentUser()?.roleName) || getCurrentUser()?.isUserAndApprover ? <div
          className={
           combineClasses( `${styles.navLink}  ${!sidebarOpen ? styles.sidebarClosed : ""}`,
            openInstruction ? styles.activeContent :'')
          }
          onClick={() => setOpenInstruction(prev => !prev) }

        >
          <div className={styles.linksNames}>
            <img src={instruction} alt="user icon" className={styles.instructIcon} />
            {sidebarOpen && <p className={styles.linkPageName}>Instruction</p>}
          </div>
        </div>: ""}

        {openInstruction && sidebarOpen ?
          <div className={styles.instructionContainer }>
            <div className={styles.instructionContent}>
              <img src={bookIcon} className={styles.instructionIcon}/>
              <div className={styles.instructionText} onClick={() => {setManualOpen("manual")}}>Manual</div>
            </div>
            <div className={styles.instructionContent}>
              <img src={videoIcon} className={styles.instructionIcon}/>
              <div className={styles.instructionText} onClick={() => {setManualOpen("video")}}>Video</div>
            </div>
          </div> : ''
        }


      </div>
        {/* <div
          className={
            `${styles.navLink} ${styles.logoutButton} ${!sidebarOpen ? styles.sidebarClosed: ""}`
          }
          onClick={clearOnLogout}

        >
          <div className={styles.linksNames}>
            <img src={logoutIcon} alt="logout icon" className={styles.icon} />
            {sidebarOpen && <p className={styles.linkPageName}>Logout</p>}
          </div>
        </div> */}
        <ViewManualVideo
                    modalOpen={manualOpen}
                    setModalOpen={setManualOpen}
                    heading={`Instructions ${manualOpen === "manual" ? "Manual" : "Video"}`}
                    className={styles.viewManual}
      />
    </div>
  );
};

export default memo(LeftSideBar);