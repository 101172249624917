import Modal from 'components/shared/modal/Modal';
import React from 'react';
import vid from "assets/png/olo.mkv"
import { getInstructionPdfPath } from 'utils/utils';

const ViewManualVideo = (props) => {
    const path = getInstructionPdfPath(props?.modalOpen)
    return (
        <Modal {...props}>

            {
            props?.modalOpen === "manual" ?
            <iframe 
                // src="http://103.165.118.9:5025/uploads/excel/48ea06ce-45ed-4b6d-bc5f-ed44928ef61f.pdf" 
                src={path}
                frameborder="0"
                className='my-third-step'
            ></iframe> :
            <video
                className='my-fifth-step'
                src={path?.src}
                type={path?.type} 
                controls
            >
            </video>
            // <iframe 
            //     src={vid} 
            //     frameborder="0"
            //     // className={className}
            // ></iframe>
            }
        </Modal>
    );
}
 
export default ViewManualVideo;